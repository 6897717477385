import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"

//Load Components
import { AboutRequest } from "components/shift/about/request"
import { RecognitionsCover } from "components/shift/recognitions/cover"
import { Awards } from "components/shift/recognitions/awards"

//Load Context
import { LangContext } from "../context/lang-context"

const RecognitionsPage = () => {
  const { lang } = useContext(LangContext)
  const data = useStaticQuery(GET_RECOGNITIONS_DETAILS)
  const block =
    lang === "EN"
      ? data?.wordPress?.RecognitionsPage?.recognitionsPage
      : data?.wordPress?.RecognitionsPage?.translation?.recognitionsPage ||
        data?.wordPress?.RecognitionsPage?.recognitionsPage

  const request =
    lang === "EN"
      ? data?.wordPress?.aboutPage?.aboutPage
      : data?.wordPress?.aboutPage?.translation?.aboutPage ||
        data?.wordPress?.aboutPage?.aboutPage

  const awardsData = data?.wordPress?.awards?.nodes

  return (
    <Layout>
      <Seo title="Recognitions" />
      <RecognitionsCover data={block.cover} />
      <Awards data={awardsData} />
      <AboutRequest data={request.request} />
    </Layout>
  )
}

export default RecognitionsPage

const GET_RECOGNITIONS_DETAILS = graphql`
  query Recognitions {
    wordPress {
      RecognitionsPage: page(id: "recognitions", idType: URI) {
        recognitionsPage {
          cover {
            title
            text
            label
            image {
              sourceUrl
            }
          }
        }
        translation(language: ID) {
          recognitionsPage {
            cover {
              title
              text
              label
              image {
                sourceUrl
              }
            }
          }
        }
      }
      aboutPage: page(id: "about", idType: URI) {
        aboutPage {
          request {
            title
            text
            image {
              sourceUrl
            }
            button {
              url
              text
            }
          }
        }
        translation(language: ID) {
          aboutPage {
            request {
              title
              text
              image {
                sourceUrl
              }
              button {
                url
                text
              }
            }
          }
        }
      }
      awards(where: { language: EN }) {
        nodes {
          award {
            from
            name
            to
            image {
              sourceUrl
            }
          }
          translation(language: ID) {
            award {
              from
              name
              to
              image {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`
