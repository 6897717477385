import React, { useContext } from "react"

import { Container, Section } from "components/anti/grid/grid"
import { Card } from "components/anti/card/card"
import { useScrollAnim } from "src/components/hooks/hooks"

//Load Context
import { LangContext } from "../../../context/lang-context"

export const Awards = ({ data }) => {
  const { lang } = useContext(LangContext)
  const [trigger, anim] = useScrollAnim()

  return (
    <Section forwardRef={trigger} className="recognitions-awards">
      <Container>
        <div className="row">
          {data.map((data, i) => {
            // const dataLang = lang === "EN" ? data : data?.translation
            const dataLang = data
            const award = dataLang?.award
            return (
              <div className="col-md-4" key={i}>
                <Card
                  isLink={false}
                  variant="boxless"
                  img={award?.image?.sourceUrl}
                  imgRatio="r-3-2"
                  label={award?.from}
                  title={award?.name}
                  className={`card-award image ${anim(2, "fadeInUp")}`}
                >
                  <p className="card-text">
                    {lang === "EN" ? "Awarded to" : "Diberikan Kepada"}{" "}
                    <strong>{award?.to}</strong>
                  </p>
                </Card>
              </div>
            )
          })}
        </div>
      </Container>
    </Section>
  )
}
