import React from "react"

import { Cover } from "../../anti/cover/cover"
import { Container } from "../../anti/grid/grid"
import { Breadcrumb } from "../../anti/breadcrumb/breadcrumb"
import { useScrollAnim } from "src/components/hooks/hooks"

export const RecognitionsCover = ({ data }) => {
  const [trigger, anim] = useScrollAnim()
  const block = data
  return (
    <div ref={trigger} className="cover-bubble-wrapper">
      <Cover
        breadcrumb={<Breadcrumb path={"/recognitions"} />}
        variant="basic"
        theme="dark"
        img={block?.image && block?.image?.sourceUrl}
        imgHeight="h-250px h-md-600px"
        buttons={block?.buttons}
      />
      <Container>
        <div className="cover-bubble">
          <div className="bubble-content">
            <div className={`bubble-bg ${anim(1)}`} />
            <h6 className={`h6 ${anim(2)}`}>{block?.label}</h6>
            <h2 className={`h2 ${anim(3)}`}>{block?.title}</h2>
            <p
              className={`${anim(4)}`}
              dangerouslySetInnerHTML={{ __html: block?.text }}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}
